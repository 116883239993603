import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import {
  AuthenticationGuard,
  AuthInterceptor,
  ForgottenPasswordComponent,
  LinkLogonComponent,
  ImpersonateLogonComponent,
  PinCodeLogonComponent,
  SigninCallbackComponent,
  SignoutCallbackComponent,
  AdminGuard, ContextHeaderInterceptor
} from '.';
import { CoreModule } from 'app/shared/core/core.module';
import { MatProgressBarModule } from '@angular/material/progress-bar';


const authRoutes: Routes = [
    { path: 'signin-callback', component: SigninCallbackComponent },
    { path: 'signout-callback', component: SignoutCallbackComponent },
    { path: 'link-logon/:token', component: LinkLogonComponent },
    { path: 'impersonate-logon/:info', component: ImpersonateLogonComponent },
    { path: 'change-password/:token', component: ForgottenPasswordComponent },
    { path: 'pin-code-logon', component: PinCodeLogonComponent }
];

@NgModule({
    declarations: [
        SigninCallbackComponent,
        SignoutCallbackComponent,
        LinkLogonComponent,
        ImpersonateLogonComponent,
        ForgottenPasswordComponent,
        PinCodeLogonComponent
    ],
    providers: [
        AuthenticationGuard,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true,
        },
        {
          provide: HTTP_INTERCEPTORS,
          useClass: ContextHeaderInterceptor,
          multi: true,
        },
        AdminGuard
    ],
    imports: [
        CoreModule,
        MatProgressBarModule,
        RouterModule.forChild(authRoutes)
    ],
    exports: [
    ]
})
export class AuthModule {

}


